@import "../../scss-variables.scss";

.wrapper-input {
    position: relative;
    margin: 4px 0;
  
    label {
      font-size: $label-size;
      color: var(--color-additional);
    }
  
    .line {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background: $lineGradient;
    }
  
    input {
     width: 100%;
     border: 0;
     padding: $inputPadding;
     border-bottom: 1px solid #ccc;
  
     &:focus {
       outline: none;
  
       & ~ .line {
        width: 100%;
        transition: 0.3s;
        }
      }
    }

    .cardBrand {
      height: 24px;
      position: absolute;
      right: 0px;
      top: 27px;
    }
  }

.error-validation {
  color: var(--color-warning);
  font-size: 0.7em;
  margin-top: 0;
}  

.amount-input-currency {
  height: 24px;
  position: absolute;
  right: 0px;
  top: 27px;
  opacity: 0.6;
}