:root {
  --color-primary: #114382;
  --color-secondary: #00c4cc;
  --color-additional: #0b78a7;
  --color-warning: #D64550;
}

.animated-form {
  animation: openForm .35s;
  animation-timing-function: ease-in;
}

@keyframes openForm {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.logo {
  margin-top: 20px;
  max-width: 280px;
  filter: drop-shadow(1px 7px 2px rgba(0,0,0,0.2));
}