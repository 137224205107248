.payId-layout {
  width: 100%;
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.instruction-text {
  font-size: 0.75rem;
  text-align: center;
  font-weight: 500;
}

.payId-block {
  border: 1px solid #f0f0f0;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
  width: 100%;
  min-width: fit-content;
}

.copy-icon {
  height: 1.25rem;
  width: 1.25rem;
  background-image: url('../../assets/img/copy.svg');
}

.copy-button {
  display: flex;
  gap: 0.25rem;
  border-radius: 0.25rem;
  padding: 0.5rem;
  cursor: pointer;
}

.checkmark {
  background-image: url('../../assets/img/checkmark.svg');
  width: 0.75rem;
  height: 0.75rem;
  visibility: hidden;
}

.checkmark-copied {
  visibility: visible;
}

.payId-detail {
  width: 100%;
  min-width: fit-content;
  list-style: none;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.payId-detail-title {
  font-weight: 500;
  font-size: 0.85rem;
  color: #999999;
}

.payId-detail-value {
  font-size: 0.9rem;
  font-weight: 600;
}
